import React, { Component } from "react";
import './styles.css';
import Header from '../../components/Header';
import ImageUpload from '../../components/ImageUpload';
import InputCard from '../CreateProfile/InputCard';
import queryString from 'query-string';
import axios from "axios";
import { serverURL } from '../../constants';
import { Button } from 'react-bootstrap';
import { environment } from '../../environment/environment';
import SimpleReactValidator from 'simple-react-validator';
import { toast, Slide } from 'react-toastify';

var _ = require('lodash/times')

export default class EditPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            cards: 1,
            walletAddress: this.props.walletAddress,
            profilePic: null,
            ipfsHash: null,
            waiting: false,
            web3: window.web3,
            contract: this.props.web3Contract,
            username: queryString.parse(this.props.location.search).username,
            sellEmail: '',
            checked: false,
            newInputVal: '',
            newInfo: {},
            data: {},


        }
        this.validator = new SimpleReactValidator({
            autoForceUpdate: this,
            className: 'text-danger',
            messages: {
                email: 'Please provide a valid email address.',
            },
        });
    }

    async componentDidMount() {
        if (queryString.parse(this.props.location.search)) {

            const username = queryString.parse(this.props.location.search).username;

            var hash = await this.props.getIpfsHashByUsername(username);
            this.getIpfsData(hash);

        }
        this.props.initWeb3()

    }


    getIpfsData = async (hash) => {
        await this.setState({ ipfsHash: hash });
        const url = `https://gateway.pinata.cloud/ipfs/${this.state.ipfsHash}`;
        fetch(url).then((res) => {
            //
            res.json().then(data => {

                this.setState({ data: data });
                console.log('the data -->', data);
            })

        })

    }

    handleSellEmail = (e) => {
        e.preventDefault();
        this.setState({ sellEmail: e.target.value });

    }
    setnewVal = (e) => {
        this.setState({ newInputVal: e.target.value })
    }
    addCard = () => {
        this.setState({ cards: this.state.cards + 1 })
    }
    userNameChange = (e) => {
        this.setState({ username: e.target.value })
    }
    addPic = (e) => {

        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0])
        reader.onload = () => {
            this.setState({ profilePic: reader.result })
        }
    }
    handleChange = (state) => {
        this.setState(prevState => {
            let info = Object.assign({}, prevState.info);
            info[state.id] = {
                platform: state.selectedPlatform,
                value: state.inputText
            };
            this.inputValidation(info);

            return { info };
        })
    }

    inputValidation = (info) => {

        for (var key in info) {
            if (info.hasOwnProperty(key)) {
                if (info[key].platform === 'web' || info[key].platform === 'twitter'
                    || info[key].platform === 'fb' || info[key].platform === 'insta'
                    || info[key].platform === 'linkedin' || info[key].platform === 'telegram') {

                    info[key].platform = 'url';
                }
            }
            this.setState({
                newInfo: info
            })

        }

    }

    validatorFunc = () => {
        const info = this.state.newInfo;

        if (info !== null) {

            return (<div className="d-flex flex-column">
                {Object.keys(info).map((key, index) => (
                    <div key={index} className="d-flex justify-content-center" >
                        {this.validator.message('', info[key].value, `required|${info[key].platform}`)}
                    </div>

                ))
                }
            </div>)
        }

    }
    pinToPinata = (final) => {
        const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;
        axios.post(url, final, {
            headers: {
                'pinata_api_key': environment.pinataApiKey,
                'pinata_secret_api_key': environment.pinataSecret
            }
        }).then((res) => {
            if (res.status === 200) {
                window.sessionStorage.setItem("ipfsHash", res.data.IpfsHash);
                this.setState({ ipfsHash: res.data.IpfsHash })
                console.timeEnd('Pinata upload time')

                this.callContract()

            } else {
                alert('error uploading to pinata')
            }
            console.log(res)
        })
            .catch((e) => {
                console.log(e)
            })
    }
    callContract = async() => {
        this.state.contract.methods.updateUserIpfs(this.state.ipfsHash).send({from:this.props.walletAddress}).on('transactionHash', (hash)=> {
            this.props.history.push({
                pathname: `/${queryString.parse(this.props.location.search).username}`,
                state: {tx: 'processing'}
            });

        })
    }
    createProfile = (e) => {
        e.preventDefault();


        if (this.validator.allValid()) {
            toast("please complete the transaction to proceed!", {
                position: toast.POSITION.BOTTOM_CENTER,
                transition: Slide,
                className: 'info-message',
                hideProgressBar: true
            });

            axios.post(serverURL + `api/registerOwner?address=${this.props.walletAddress}&email=${this.state.sellEmail}&username=${this.state.username}`)
                .then(function (response) {
                    toast("Profile Updated !", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        transition: Slide,
                        className: 'info-message',
                        hideProgressBar: true
                    });

                })


            var final = { pic: this.state.profilePic }
            Object.values(this.state.info).map((v) => {
                final[v.platform] = v.value
            })
            this.pinToPinata(final)

        } else {
            this.validator.showMessages();
        }

    }
    onChange = (e) => {
        this.setState({ checked: e.target.checked })
    }
    addButton() {
        return (
            <div align="center">
                <Button
                    variant="light"
                    className="add-fields"
                    onClick={() => this.addCard()}
                >
                    +
                </Button>
            </div>
        )
    }

    render() {
        return (
            <div style={{ padding: '0 16px' }}>
                <Header web3Resolved={this.props.web3Resolved}
                    web3={this.props.web3}
                    getStatus={this.props.getStatus} />
                <div ><br />
                    <div className="createHeader">
                        Update your profile
                    </div>

                    <div className="createHeads">
                        PROFILE PHOTO &nbsp;
                        <span style={{ color: "red" }}>
                            ✴
                        </span>
                    </div>
                    <div style={{ height: "160px" }}>

                        <ImageUpload addPic={this.addPic} profilePic={this.state.profilePic} />
                        <div className="createHeads" style={{ paddingTop: "32px" }}>
                            USERNAME &nbsp;
                        <span style={{ color: "red" }}>
                                ✴
                        </span>
                        </div>
                        <form onSubmit={this.createProfile}>
                            <div className="usernameInput">
                                <div className="usernameInputFixed">
                                    www.mch.am/
                                <div className="usernameinputTypeBlock">
                                        <input className="usernameInputType " value={this.state.username} onChange={this.userNameChange} />

                                    </div>
                                </div>

                            </div>
                            <div>
                                {
                                    _(this.state.cards, i => <InputCard handleChange={this.handleChange} key={i} id={i} />)
                                }
                                {this.validatorFunc()}
                            </div>


                            {this.addButton()}
                            <br />
                            {this.state.checked &&
                                <div className="emailInput">
                                    <input className="emailInput-inner" type="email" onChange={this.handleSellEmail}></input>
                                </div>
                            }

                            <div className="d-flex justify-content-center">
                                <Button
                                    variant="light"
                                    type="submit"
                                    className="createProfileBtn"
                                >
                                    UPDATE PROFILE
                             </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}
