import React, { Component } from 'react'
import './styles.css'
import logo from '../../assets/mchIcon.png';
import Footer from '../../components/Footer';
import InfoCard from '../../components/InfoCard'
import 'bootstrap/dist/css/bootstrap.css';
import { Container, Row, Col, Image } from 'react-bootstrap';
import profileSample from '../../assets/mchProfile.png';
import { toast, Slide } from 'react-toastify';

export default class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usernameInput: null,
            walletAddress: null,
        }
    }
    handleUsernameInput = (e) => {
        this.setState({ usernameInput: e.target.value })
    }

    onBoardUser = async () => {

        if (this.state.usernameInput === null) {
            toast("No username provided !", {
                position: toast.POSITION.BOTTOM_CENTER,
                transition: Slide,
                className: 'info-message',
                hideProgressBar: true,
                toastId: 'noName'

            });
        } else {

            if (await this.props.usernameTaken(this.state.usernameInput)) {
                toast("Username Already Taken", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    transition: Slide,
                    className: 'warning-message',
                    hideProgressBar: true,
                    toastId: 'nameTaken'
                });
            }
            else {
                if (window.ethereum === undefined) {
                    toast("Install Metamask to Proceed", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        transition: Slide,
                        className: 'info-message',
                        hideProgressBar: true,
                        toastId:'metamask'
                    });
                    return;
                }
                await this.props.initWeb3();

                window.ethereum.enable().then(async (res) => {
                    if (await this.props.hasAccount(res[0])) {
                        this.state.username = await this.props.getUsernameByAddress(res[0]);
                        
                        this.props.history.push(`/${this.state.username}`);
                        toast(`Welcome back ${this.state.username} !`, {
                            position: toast.POSITION.BOTTOM_CENTER,
                            transition: Slide,
                            className: 'info-message',
                            hideProgressBar: true,
                            toastId:'welcomeBack'
                        });

                    } else {
                        this.props.history.push(`/create?username=${this.state.usernameInput}`);
                    }
                });


            }
        }
    }

    fetchUserProfile = async () => {
        if (window.ethereum === undefined) {
            toast(`Please install metamask to proceed !`, {
                position: toast.POSITION.BOTTOM_CENTER  ,
                transition: Slide,
                className: 'info-message',
                hideProgressBar: true,
                toastId:'noMetamask'
            });
            return;
        }
        window.ethereum.enable().then(async (res) => {
            if (await this.props.hasAccount(res[0])) {
                this.state.username = await this.props.getUsernameByAddress(res[0]);
                this.props.history.push(`/${this.state.username}`);
                toast(`Welcome Back ${this.state.username} !`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    transition: Slide,
                    className: 'info-message',
                    hideProgressBar: true,
                    toastId:'mch-user'
                });
            } else {
                console.log('inside this')
                toast(`No account found , please create a usernme to get started  !`, {
                    position: toast.POSITION.BOTTOM_CENTER,
                    transition: Slide,
                    className: 'info-message',
                    hideProgressBar: true,
                    toastId: 'noAccount'

                });
              

            }
        });
    }
    render() {
        return (
            <Container fluid>
                <Row style={{ marginTop: '97px' }} className="mch-home">
                    <Col lg={6}>
                        <Image
                            src={logo}
                            alt="logo"
                            className="logo"
                            style={{ marginLeft: '25px' }}
                        />
                        <InfoCard handleUsernameInput={this.handleUsernameInput}
                                  fetchUserProfile={this.fetchUserProfile}
                                  onBoardUser={this.onBoardUser} />
                    </Col>
                    <Col lg={6}>
                        <Image src={profileSample} alt="profile" fluid />
                    </Col>
                </Row>
                <Footer/>
            </Container>
        );
    }

}
