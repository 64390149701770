import React, { Component } from "react"
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './styles.css'
import sampleImage from '../../../assets/kitty.svg';
export default class ProfileHeader extends Component {

    render() {
        return (
            <div className="profileBasic">


                <img src={
                    (this.props.data.pic === null) ? sampleImage : this.props.data.pic
                } alt="kitty" style={(this.props.data.pic === null) ? { height: "100px", width: "100px" } : { height: "100px", width: "100px", borderRadius: "50%", border: "4px solid #ac93ff" }} /><br />
                <div className="name">
                    @{this.props.username}
                </div>
                {
                    (this.props.data.hasOwnProperty('loc') ?
                        <div className="location">{this.props.data.loc}</div>
                        : ''
                    )
                }
                {
                    (this.props.data.hasOwnProperty('bio') ?
                        <div className="bio">{this.props.data.bio}</div>
                        : ''
                    )
                }
                <div style={{ marginTop: "30px", paddingBottom: "10px" }}>
                    <a href={`https://mycoinhome.com/${this.props.username}`} className="profileLink" >www.mch.am/{this.props.username} </a><FontAwesomeIcon style={{ color: 'white', fontSize: '12px' }} icon={faExternalLinkAlt} />
                </div>

            </div>
        )
    }
}
