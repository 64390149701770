import React, { Component } from "react";
import './styles.css';
import Select from 'react-select';
import { groupedOptions } from './optionsConstants';
import { colourStyles } from './dropDownStyles';



export default class InputCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlatform: null,
            inputText: null,
            id: this.props.id
        }
    }

    handleLocalChange = (selectedPlatform) => {
        this.setState({ selectedPlatform: selectedPlatform.value });
    }
    handleTextChange = async (e) => {
        await this.setState({ inputText: e.target.value });
        this.props.handleChange(this.state)
    }
  
    render() {
        const { selected } = this.state;
        return (
            <div>
                <div className="createHeads" style={{ paddingTop: "24px" }}>
                    TITLE &nbsp;
                        <span style={{ color: "red" }}>
                        ✴
                        </span>
                </div>
                <div className="inputCardBox d-flex justify-content-center">
                    <div className="inputCardBox-inner">
                        <div >
                            <Select
                                value={selected}
                                onChange={this.handleLocalChange}
                                options={groupedOptions}
                                styles={colourStyles}
                                placeholder='select field'
                                className="options-select"
                                components={{
                                    IndicatorSeparator: () => null
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ float: 'left' }}>

                        <input placeholder="Enter links/address in this field"
                               className="newField"
                               disabled={this.state.selectedPlatform == null} 
                               onChange={this.handleTextChange}
                               required />
                                   {/* {this.props.validators} */}
                            
                    </div>
                </div>
                {this.props.on}
            </div>
        )
    }
}
