export const colourStyles = {
    placeholder: styles => ({
      ...styles,
      color: '#fafafa',
    }),
    control: styles => ({
      ...styles,
      height: 50,
      minHeight: 50,
      backgroundColor: 'rgba(255, 255, 255, 0.30);',
      fontFamily: 'HelveticaNeue,Arial, sans-serif',
      fontSize: '18px',
      formatCreateLabel: 'Select',
      border: 0,
      boxShadow: 'none',
      cursor: 'pointer',
    }),
    option: (styles, { isSelected, isDisabled }) => ({
      ...styles,
      backgroundColor: 'white',
      // color: '#aa2fe9',
      color: isDisabled
        ? '#ccc'
        : isSelected
        ? 'white'
          ? '#22a8d0b5'
          : 'white'
        : 'purple',
      '&:hover': {
        backgroundColor: '#22a8d0b5',
        borderRadius: '5px',
        cursor: 'pointer',
      },
    }),
    container: styles => ({
      ...styles,
      width: 150,
    }),
    singleValue: styles => ({
      ...styles,
      color: '#fafafa',
    }),
    input: styles => ({
      ...styles,
      color: "white"
    })
  };
  
  