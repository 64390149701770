import React, { Component } from 'react';
import greenDot from '../../assets/greenDot.png';
import redDot from '../../assets/redDot.png';
import './styles.css';

export default class MetamaskConnnect extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: false
        }

    }

    render() {
        return (
            <div className="d-flex flex-row-reverse bd-highlight">
                <div className="p-2 bd-highlight">
                {(this.props.web3Resolved) ? <p className="connected-text">connected to MetaMask</p> :
                <p className="connected-text">No Wallet</p>
                }
                </div>
                <div className="p-2 bd-highlight">
                {(this.props.web3Resolved) ? <div><img src={greenDot} alt="green dot" data-tip data-for='connected'/></div> :
                <div><img src={redDot} alt="red dot" data-tip data-for='not-connected'/> </div>
                }
                </div>
            </div>
        )
    }
}
