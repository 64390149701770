import React  from 'react'
import './styles.css'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faExternalLinkAlt, faQrcode } from '@fortawesome/free-solid-svg-icons';
import { toast, Slide } from 'react-toastify';

// Card style when there is no hovering
const regularStyle = {
    color: "rgba(38, 50, 56, 0.7)"
}
// Alternative styling
const hoverStyle = {
    borderColor: "#743cd591",
    cursor: "pointer"

}

export default class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverStatus: false,
        }
    }
    toggleHover = () => {
        this.setState({ hoverStatus: !this.state.hoverStatus })
    }

    generateQr = (qrValue) => {
        const qrInfo = {
            qrCode: qrValue,
            qrBool: true
        }
        this.props.parentCallback({ qrInfo });

    }
  successMessage() {
    toast("copied to clipboard",{
        position: toast.POSITION.BOTTOM_CENTER,
        transition: Slide,
        className: 'info-message',
        hideProgressBar: true
      });
  }
    render() {
        return (
            <div className="card"
                style={(this.state.hoverStatus) ? hoverStyle : regularStyle}
                onMouseEnter={this.toggleHover}
                onMouseLeave={this.toggleHover}>
                <div className="d-flex">
                    <div className="p-2  ">
                        <FontAwesomeIcon icon={this.props.logo} /></div>
                    <div className="p-2  ">
                        {this.props.platform}</div>
                    <div className="p-2 ellipsis-text">
                        {this.props.address}
                    </div>
                    <div className="p-2 ml-auto">
                        {
                            (this.state.hoverStatus) ?
                                <div className="row">
                                    <div className="col" >
                                        <CopyToClipboard text={this.props.address}
                                            onCopy={() => this.successMessage()}>
                                            <FontAwesomeIcon style={{ color: '#9c9c9c' }} icon={faCopy} />
                                        </CopyToClipboard>
                                    </div>
                                    <div className="col">
                                        {(this.props.platform === "Ethereum" || this.props.platform === "Bitcoin")
                                            ? <div onClick={() => this.generateQr(this.props.address)}><FontAwesomeIcon icon={faQrcode} /></div>
                                            : <a href={'http://' + this.props.address} target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon style={{ color: '#9c9c9c' }} icon={faExternalLinkAlt} />
                                            </a>}
                                    </div>
                                </div>
                                : null}
                    </div>
                </div>
            </div>
        )
    }
}
