import React from "react";
import ProfileHeader from './ProfileHeader';
import Header from '../../components/Header';
import Card from '../../components/Card';
import queryString from 'query-string';
import { Modal } from 'react-bootstrap';
import QRCode  from 'qrcode.react';

import './styles.css';

import {
    faGlobeAmericas,
    faEnvelope,
    faPhoneAlt,
    faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import {
    faTelegram,
    faFacebook,
    faTwitter,
    faInstagram,
    faLinkedinIn,
    faBitcoin,
    faEthereum
  } from '@fortawesome/free-brands-svg-icons';
export default class UserProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null,
            ipfsHash: null,
            display: false,
            data: null,
            bidStatus: false,
            owner: null,
            modalOpen: false,
            qrCode: '',
        }

    }
    async componentDidMount() {
        if (this.props.location.state !== undefined && this.props.location.state.tx === 'processing' ) {
            if (window.sessionStorage.getItem('ipfsHash') === null) {
                this.props.history.push(`/${this.props.match.params.username}`);
            } else {
                this.getIpfsData(window.sessionStorage.getItem('ipfsHash'))
            }
        } else {
            var hash = await this.props.getIpfsHashByUsername(this.props.match.params.username)
            if (hash === '') {
                this.props.history.push('/not-found')
            } else if (hash === '0') {
                this.props.history.push(`/edit?username=${this.props.match.params.username}`)
            }
            else {
                this.getIpfsData(hash)
            }
        }

    }
    getIpfsData = async (hash) => {
        await this.setState({ ipfsHash: hash });
        const url = `https://gateway.pinata.cloud/ipfs/${this.state.ipfsHash}`;
        fetch(url).then((res) => {
            //
            res.json().then(data => {
                this.setState({ data: data });
                this.setState({ display: true })
            })

        })

    }
    allowBids = async () => {
        var bidStatus = await this.props.isAvailableForSale(this.props.match.params.username);
        this.setState({ bidStatus: bidStatus })
    }
    componentWillMount = async () => {
        const owner = await this.props.getUsernameOwner(this.props.match.params.username);
        this.props.setOwner(owner)
        this.allowBids()

    }
    callbackFunction = (qrValue) => {
        this.setState({modalOpen : qrValue.qrInfo.qrBool,
                        qrCode: qrValue.qrInfo.qrCode})
        
    }
    onClose= ()=>{
        this.setState({modalOpen: false})
    }
    renderModal= ()=>{
        return(  

            <Modal
                show
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                onHide={() => this.onClose()}
                centered
            >
                <Modal.Header closeButton >
                    <Modal.Title id="contained-modal-title-vcenter">
                        QR Code
              </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="d-flex justify-content-center">
                        <QRCode value={this.state.qrCode} />
                    </div>

                </Modal.Body>
            </Modal>
          );
    }

    render() {
        return (
            <div>
                {this.state.modalOpen ? this.renderModal() : null}
                <div className="overlay">
                    {(queryString.parse(this.props.location.search).tx === 'processing') ?
                        <div></div>
                        //   <Alert message=" Transaction is being processed, it may take up to 15-30 seconds to be mined." type="warning" showIcon />
                        : null}


                    <Header username={this.props.match.params.username} 
                            getStatus={this.props.getStatus} 
                            enableBid={this.props.enableBid} 
                            disableUsernameSale={this.props.disableUsernameSale}
                            bidStatus ={this.state.bidStatus} 
                            initSettings={this.props.initSettings} 
                            closeSettings = {this.props.closeSettings} 
                            settingsOpen={this.props.settingsOpen} 
                            web3Infura={this.props.web3Infura} 
                            owner={this.state.owner} 
                            initWeb3 = {this.props.initWeb3} 
                            web3={this.props.web3}  
                            web3Resolved = {this.props.web3Resolved} 
                            walletAddress={this.props.walletAddress}/>
                    <div><br />
                        {(this.state.display) ? 
                        <ProfileHeader bidStatus ={this.state.bidStatus} 
                                       username={this.props.match.params.username} 
                                       data={this.state.data} />: ''}
                    </div>
                </div>
                {this.state.display &&
                    <div className="infoBox">
                        <div className="about">
                            <div className="subtitle">
                                About
                        </div>
                            {
                                (this.state.data.hasOwnProperty('web')) ?
                                    <Card logo={faGlobeAmericas}
                                          address={this.state.data.web} 
                                          platform="Address" /> : ''
                            }
                            {
                                (this.state.data.hasOwnProperty('email')) ?
                                    <Card logo={faEnvelope} 
                                          address={this.state.data.email} 
                                          platform="Email" /> : ''
                            }
                            {
                                (this.state.data.hasOwnProperty('phone')) ?
                                    <Card logo={faPhoneAlt} 
                                          address={this.state.data.phone} 
                                          platform="Phone" /> : ''
                            }
                            {
                                (this.state.data.hasOwnProperty('twitter')) ?
                                    <Card logo={faTwitter} 
                                          address={this.state.data.twitter} 
                                          platform="Twitter" /> : ''
                            }
                            {
                                (this.state.data.hasOwnProperty('fb')) ?
                                    <Card logo={faFacebook} 
                                          address={this.state.data.fb} 
                                          platform="Facebook" /> : ''
                            }
                            {
                                (this.state.data.hasOwnProperty('insta')) ?
                                    <Card logo={faInstagram}
                                          address={this.state.data.insta} 
                                          platform="Insta" /> : ''
                            }
                            {
                                (this.state.data.hasOwnProperty('linkedin')) ?
                                    <Card logo={faLinkedinIn} 
                                          address={this.state.data.linkedin} 
                                          platform="LinkedIn" /> : ''
                            }
                            {
                                (this.state.data.hasOwnProperty('telegram')) ?
                                    <Card logo={faTelegram}
                                          address={this.state.data.telegram} 
                                          platform="Telegram" /> : ''
                            }



                        </div>
                        <div className="wallets">
                            <div className="subtitle">
                            Wallets
                            </div>
                            {
                                (this.state.data.hasOwnProperty('btc')) ?
                                    <Card logo={faBitcoin} 
                                          parentCallback = {this.callbackFunction}
                                          address={this.state.data.btc} 
                                          platform="Bitcoin" /> : ''
                            }
                            {
                                (this.state.data.hasOwnProperty('eth')) ?
                                    <Card logo={faEthereum} 
                                          parentCallback = {this.callbackFunction}
                                          address={this.state.data.eth} 
                                          platform="Ethereum" /> : ''
                            }
                            {
                                (this.state.data.hasOwnProperty('ltc')) ?
                                    <Card logo={faMoneyBill} 
                                          parentCallback = {this.callbackFunction}
                                          address={this.state.data.ltc}
                                          platform="LiteCoin" /> : ''
                            }
                            {
                                (this.state.data.hasOwnProperty('bch')) ?
                                    <Card logo={faMoneyBill} 
                                          parentCallback = {this.callbackFunction}
                                          address={this.state.data.bch} 
                                          platform="BitCoin Cash" /> : ''
                            }


                        </div>
                    </div>
                }
            </div>
        )
    }
}
