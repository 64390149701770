import React,{Component} from "react"
import Wrapper from './Wrapper';
export default class Header extends Component{
        // history = useHistory();

    constructor(props){
        super(props);
        this.state = {
        };

    }
    render(){
        return(
            <Wrapper>
                <section style={{textAlign:'star',
                                color:'#eceff1',
                                fontFamily:'HelveticaNeue',
                                fontSize:'12px'}}>
                © 2020. All rights Reserved
                </section>
                <section style={{textAlign:'end',
                                 color:'#eceff1',
                                 fontFamily:'HelveticaNeue',
                                 fontSize:'14px'
                                 }}>
                Made with love by <a href="https://www.wipiway.com/">Wipiway</a>.
                </section>
            </Wrapper>
        )
    }
}
