const socialAccounts = [
    { value: 'web', label: 'Website' },
    { value: 'email', label: 'Email' },
    { value: 'phone', label: 'Phone' },
    { value: 'loc', label: 'Location' },
    { value: 'twitter', label: 'Twitter' },
    { value: 'fb', label: 'Facebook' },
    { value: 'insta', label: 'Instagram' },
    { value: 'linkedin', label: 'Linkedin' },
    { value: 'telegram', label: 'Telegram' },
    { value: 'bio', label: 'Bio' },

];
const cryptoWallet = [
    { value: 'btc', label: 'Bitcoin' },
    { value: 'eth', label: 'Ethereum' },
    { value: 'ltc', label: 'Litcoin' },
    { value: 'bch', label: 'Bitcoin Cash' }
];

export const groupedOptions = [
    {
        label: 'Social Media',
        options: socialAccounts,
    },
    {
        label: 'Crypto wallets',
        options: cryptoWallet,
    },
];


