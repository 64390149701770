import React, { Component } from "react"
import MetamaskConnnect from '../../containers/metamaskConnect';

import { Image } from 'react-bootstrap';
import logo from '../../assets/mchIcon.png';
import Wrapper from './Wrapper';
import { faCog, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';

export default class Header extends Component {

    // history = useHistory();

    constructor(props) {
        super(props);
        this.state = {
            displayMenu: false,
        };

        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);

    }
    openModal = async () => {
        this.props.initSettings()
    }

    componentDidMount() {
    }

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true }, () => {
            document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });

    }


    render() {
        return (
            <Wrapper>
                <section>
                    <a href="/">
                        <Image
                            src={logo}
                            alt="logo"
                            className="logo"
                            style={{ width: '56px', height: '56px', cursor: 'pointer' }}
                        />
                    </a>
                </section>
                <section>
                    <div className="d-flex justify-content-end">

                        <MetamaskConnnect web3Resolved={this.props.web3Resolved}
                            web3={this.props.web3}
                            getStatus={this.props.getStatus} />
                        <div  >
                            <div onClick={this.showDropdownMenu}>
                                <FontAwesomeIcon
                                    style={{
                                        marginRight: '10px',
                                        marginTop: '12px',
                                        marginLeft: '16px',
                                        fontSize: '20px',
                                        color: '#eceff1',
                                        cursor:'pointer'
                                    }}
                                    icon={faCog}

                                />
                            </div>

                            {this.state.displayMenu ? (
                                <ul>
                                    <li><a className="active" href={`/edit?username=${this.props.username}`}>
                                        <FontAwesomeIcon icon={faUserEdit} /> Edit Profile</a></li>
                                </ul>
                            ) :
                                (
                                    null
                                )
                            }

                        </div>
                    </div>
                </section>
            </Wrapper>
        )
    }
}
