import React, { Component } from 'react';
import './styles.css';
import picture from '../../assets/picture.png';
import Dropzone from 'react-dropzone';


export default class ImageUpload extends Component {

    componentDidMount = () => {
    }
    render() {
        return (
            <div >
                <Dropzone  >
                    {({ getRootProps, getInputProps }) => (
                        <section>
                            <div className="outerCircle" {...getRootProps()}>
                                <div className="innerCircle">
                                    <img src={picture} alt="pic" className="uploadPicture" />
                                    <div className="uploadPicText">
                                        {(!this.props.profilePic) && 'UPLOAD PHOTO'}
                                        {this.props.profilePic && 'UPLOADED ✔'}
                                        <input {...getInputProps()} accept="image/*" type="file" onChange={this.props.addPic} />
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>

        )
    }
}