import React, { Component } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { abi, contractAddress, serverURL } from '../constants';
import Web3 from 'web3';
import Axios from 'axios';

import CreateProfile from '../containers/CreateProfile';
import HomePage from '../containers/HomePage';
import UserProfile from "../containers/UserProfile";
import NotFoundPage from "../containers/NotFoundPage";
import EditPage from "../containers/EditProfile";

let web3
const web3Infura = new Web3(new Web3.providers.HttpProvider('https://rinkeby.infura.io/v3/5b39e9ad55d7499797ffcf33c93e666a'));
try {
    web3 = new Web3(window.web3.currentProvider);
} catch (e) {
    web3 = new Web3(new Web3.providers.HttpProvider('https://rinkeby.infura.io/v3/5b39e9ad55d7499797ffcf33c93e666a'));
}


export default class RouterConfig extends Component {
    constructor(props) {
        super(props);
        this.state = {
            web3Resolved: false,
            walletAddress: null,
            web3: web3,
            infuraContract: new web3Infura.eth.Contract(abi, contractAddress),
            username: null,
            web3Contract: new web3.eth.Contract(abi, contractAddress),
            settingsOpen: false,
            owner: null,
            balanceInWei: null,
            bids: null,
            bidsResolved: false
        }
    }

    getStatus = async () => {

        web3.eth.getAccounts(function (err, accounts) {
            if (err != null) {
                console.error("An error occurred: " + err);
            }
            else if (accounts.length === 0) {
                console.log("User is not logged in to MetaMask");
                return false
            }
            else {
                console.log("User is logged in to MetaMask");
                return true
            }
        });
    }

    setOwner = (owner) => {
        this.setState({ owner: owner })
    }
    disableUsernameSale = () => {
        this.state.web3Contract.methods.disableUsernameSale.send({ from: this.state.walletAddress }).then((res) => {
            console.log(res)
        })
    }
    enableBid = () => {
        this.state.web3Contract.methods.allowUsernameSale.send({ from: this.state.walletAddress }).then((res) => {
            console.log(res)
        })
    }
    initSettings = async () => {
        window.ethereum.enable().then(async (res) => {
            this.setState({ walletAddress: res[0] });
            try {
                const checkWeb3  = (await web3.eth.getAccounts())[0];
                const browserNetwork = await web3.eth.net.getNetworkType()
                if (browserNetwork !== "rinkeby") {
                    // Swal.fire('', 'Network unsupported, Switch to rinkeby net','warning')
                } else {
                    this.setState({ web3Resolved: true })
                    this.setState({ username: await this.getUsernameByAddress(this.state.walletAddress) })
                    console.log(await web3Infura.utils.toChecksumAddress(this.state.walletAddress) === this.state.owner)
                    if (await web3Infura.utils.toChecksumAddress(this.state.walletAddress) === this.state.owner) {
                        this.setState({ settingsOpen: true });
                    } else {
                        // Swal.fire('Error', 'Not your account!', 'warning')
                    }
                    this.setState({walletAddress:checkWeb3})
                }
            } catch (e) {
                console.log(e);
                // Swal.fire('', 'Please install/unlock metamask!','warning')
            }
        })
    }
    closeSettings = () => {
        this.setState({ settingsOpen: false });
    }
    initWeb3 = async () => {
        window.ethereum.enable().then(async (res) => {
            this.setState({ walletAddress: res[0] });
            try {
                const checkWeb3  = (await web3.eth.getAccounts())[0];
                const browserNetwork = await web3.eth.net.getNetworkType()
                if (browserNetwork !== "rinkeby") {
                    // Swal.fire('', 'Network unsupported, Switch to rinkeby net','warning')
                } else {
                    this.setState({ web3Resolved: true })
                    web3Infura.eth.getBalance(this.state.walletAddress, (err, balance) => {
                        this.setState({ balanceInWei: balance })

                    });
                    this.setState({ username: await this.getUsernameByAddress(this.state.walletAddress) })
                    this.setState({walletAddress:checkWeb3})
                }
            } catch (e) {
                console.log(e);
                // Swal.fire('', 'Please install/unlock metamask!','warning')
            }
        })

    }
    usernameTaken = async (username) => {
        var taken = new Promise((resolve) => {
            this.state.infuraContract.methods.usernameTaken(username).call().then((res) => {
                resolve(res)
            })
        })
        return taken
    }
    hasAccount = async (account) => {
        var registered = new Promise((resolve) => {
            this.state.infuraContract.methods.hasUser(account).call().then((res) => {
                resolve(res)
            })
        })
        return registered
    }

    getOwner = async (username) => {
        var owner = new Promise((resolve) => {
            this.state.infuraContract.methods.getOwner(username).call().then((res) => {
                resolve(res)
            })
        })
        return owner
    }
    getBids = async (username) => {
        this.state.infuraContract.methods.getBids(username).call().then((res) => {
            this.setState({ bids: res });
            this.setState({ bidsResolved: true })
        })
    }
    approveBid = async (bidIndex, email) => {
        Axios.post(serverURL + `api/approveBid?email=${email}`)
        this.state.web3Contract.methods.approveBid(bidIndex).send({ from: this.state.walletAddress }).then((res) => {
            console.log(res)
        })
    }
    denyBid = async (bidIndex, email) => {
        Axios.post(serverURL + `api/denyBid?email=${email}`)
        this.state.web3Contract.methods.denyBid(bidIndex).send({ from: this.state.walletAddress }).then((res) => {
            console.log(res)
        })
    }
    getIpfsHashByUsername = async (username) => {
        var ipfsHash = new Promise((resolve) => {
            this.state.infuraContract.methods.getIpfsHashByUsername(username).call().then((res) => {
                resolve(res)
            })
        })
        return ipfsHash
    }
    getUsernameByAddress = async (address) => {
        var username = new Promise((resolve) => {
            this.state.infuraContract.methods.getUsernameByAddress(address).call().then((res) => {
                resolve(res)
            })
        })
        return username
    }
    getEscrowStatus = async (username) => {
        var status = new Promise((resolve) => {
            this.state.infuraContract.methods.getEscrowStatus(username).call().then((res) => {
                resolve(res)
            })
        })
        return status
    }
    isAvailableForSale = async (username) => {
        var isAvailable = new Promise((resolve) => {
            this.state.infuraContract.methods.isAvailableForSale(username).call().then((res) => {
                resolve(res)
            })
        })
        return isAvailable
    }
    getUsernameOwner = async (username) => {
        var owner = new Promise((resolve) => {
            this.state.infuraContract.methods.getOwner(username).call().then((res) => {
                resolve(res)
            })
        })
        return owner
    }
    placeBid = async (username, amount) => {
        this.state.web3Contract.methods.bidForUsername(username).send({ from: this.state.walletAddress, value: amount }).then((res) => {
            console.log(res)
        })
    }
    render() {
        return (
            <div>
                <Router>
                    <Switch>
                        <Route exact path="/" render={(props) => <HomePage  {...props}
                            web3Contract={this.state.web3Contract}
                            walletAddress={this.state.walletAddress}
                            hasAccount={this.hasAccount}
                            usernameTaken={this.usernameTaken}
                            getUsernameByAddress={this.getUsernameByAddress}
                            web3={this.state.web3}
                            web3Resolved={this.state.web3Resolved}
                            initWeb3={this.initWeb3} />} />
                        <Route exact path="/create" render={(props) => <CreateProfile {...props}
                            walletAddress={this.state.walletAddress}
                            web3Contract={this.state.web3Contract}
                            web3Resolved={this.state.web3Resolved}
                            initWeb3={this.initWeb3} />} />
                        <Route exact path="/not-found" component={NotFoundPage} />
                        <Route exact path="/edit" render={(props)=>  <EditPage 
                                    {...props} 
                                    walletAddress={this.state.walletAddress}  
                                    web3Contract={this.state.web3Contract} 
                                    web3Resolved={this.state.web3Resolved} 
                                    initWeb3={this.initWeb3}
                                    getIpfsHashByUsername={this.getIpfsHashByUsername}
                                    web3Infura={web3Infura}
                                    getUsernameOwner={this.getUsernameOwner}
                                    getStatus={this.getStatus}

                                />} />


                        <Route exact path="/settings" />
                        <Route path="/:username" render={(props) => <UserProfile {...props}
                            enableBid={this.enableBid}
                            disableUsernameSale={this.disableUsernameSale}
                            setOwner={this.setOwner}
                            initSettings={this.initSettings}
                            closeSettings={this.closeSettings}
                            settingsOpen={this.state.settingsOpen}
                            web3Infura={web3Infura}
                            getUsernameOwner={this.getUsernameOwner}
                            initWeb3={this.initWeb3}
                            isAvailableForSale={this.isAvailableForSale}
                            getPrice={this.getPrice}
                            getIpfsHashByUsername={this.getIpfsHashByUsername}
                            web3={this.state.web3}
                            walletAddress={this.state.walletAddress}
                            infuraContract={this.state.infuraContract}
                            getStatus={this.getStatus}
                            web3Resolved={this.state.web3Resolved} />} />
                    </Switch>

                    {/*<Route exact path="/:id/" component={Profile} />*/}
                </Router>
            </div>
        )
    }
}
