import React, { Component } from 'react';
import './styles.css';
import { Button } from 'react-bootstrap';

export default class NotFoundPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }
    homePage(){
        this.props.history.push(`/`);
    }
    render() {
        return (
            <div id="main">
                <div className="fof">
                    <h1 className="error-text">Error 404 </h1>
                    <p className="error-text">the page you are looking for is not avaible!</p>
                    <p></p>
                    <Button variant="light" 
                            className="home-button"
                            onClick = {()=>this.homePage() }>Go to Home</Button>
                </div>
            </div>
        )
    }
}
