import React, { Component } from "react"

import './styles.css'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import LearnMore from "./LearnMore";
const features = [
  "Tamper-proof profile",
  "One link for all social profiles",
  "Short & Easy to share URL",
  "Promote your profile",
  "Rinkeby test network*"
]
export default class InfoCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };

  }
  render() {
    return (
      <div>
        <div className="headline">
          Get your own public page <br />
        on the blockchain
      </div>
        <div className="feature">
          {features.map(v => (
            <p key={v}>
              {' '}
              <FontAwesomeIcon
                style={{ marginRight: '10px' }}
                icon={faCheckCircle}
              />
              {v}
            </p>
          ))}
        </div>
        <br />
        <div className="nameInput">
          <Row>
            <div className="mchAM-cover" style={{ paddingRight: '14px' }}>
              <div className="mchAM">mch.am/</div>
            </div>
            <Col xs={4} className="usernameText-cover">
              <div className="usernameText">
                <input
                  type="text"
                  placeholder="name"
                  onChange={this.props.handleUsernameInput}
                />
              </div>
            </Col>
            <Col xs={4} className="start-col">
              <Button
                onClick={this.props.onBoardUser}
                className="btn btn-primary startBTN-cover"
              >
                Start
            </Button>
            </Col>
          </Row>
          <Row>
            <p
              style={{
                marginLeft: '3.5%',
                marginTop: '8px',
                opacity: '0.5',
                width: '400px',
                color: '#fafafa',
                fontFamily: 'HelveticaNeue,Arial, sans-serif',
              }}
            >
              eg. : mch.am/elonmusk
          </p>
          </Row>
        </div>
        <div className="d-flex flex-column login-button">
            <div className="p-2 login-text" style={{color:'white'}}>OR</div> 
            <div className="p-2">
            <Button variant="info" onClick={this.props.fetchUserProfile}>
              Log In
            </Button>
            </div>
        </div>
      
        <br />
        <br />

        {/* <button  onClick={this.openModal} className="learnMoreBtn">LEARN MORE</button> */}
      </div>
    )
  }
}
